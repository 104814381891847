import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, FormErrors } from "@axeedge/go-teacher-components";
import { WONDE_SYNC_STUDENTS } from "../../services/graphql";
import {  AlertCircle, CheckCircle, Delete } from "react-feather";
import { WONDE_SYNC_STEPS } from "../../../../services/constants";
import PageHeader from "../../../../components/PageHeader";
import ManualStudentItem from "./ManualStudentItem";

import styles from '../../WondeSync.module.scss';
import cx from 'classnames';

const ManualMatchContent = ({ school, manualStudents, setCurrentStep }) => {

    const [students, setStudents] = useState(manualStudents)
    const [formErrors, setFormErrors] = useState([]);
    const [stMatchError, setStMatchError] = useState(false);

    const [wondeSyncStudents, { loading }] = useMutation(WONDE_SYNC_STUDENTS, {
        update: (_, { data }) => {
            if (data && data.wondeSyncStudents.errors && data.wondeSyncStudents.errors.length > 0) {
                setFormErrors(data.wondeSyncStudents.errors);
                return;
            }
            if (data && data.wondeSyncStudents?.students) {
                setCurrentStep(WONDE_SYNC_STEPS.syncClasses)
            }
        }
    });


    const getStats = field => {
        if (field === 'matched') {
            return [...students].filter(el => (el.wondeStudentId && el.wondeStudentId !== '-1')).length;
        }
        if (field === 'not_matched') {
            return [...students].filter(el => !el.wondeStudentId).length;
        }
        if (field === 'removed') {
            return students.filter(el => el.wondeStudentId === '-1').length || 0;
        }
    }

    useEffect(() => {
        if (!students.find(el => !el.wondeStudentId) && stMatchError) {
            setStMatchError(null);
        }
    }, [students, stMatchError])

    const finalizeManualSync = () => {
        if (students.find(el => !el.wondeStudentId)) {
            setStMatchError(true);
            return
        }
        const studentDetails = [];
        students.forEach(st => {
            studentDetails.push({
                wondeStudentId:  st.wondeStudentId,
                studentId: st.id
            })
        })

        wondeSyncStudents({
            variables: {
                schoolId: school.id,
                wondeStudentDetails: studentDetails
            }
        });
    }

    return (
        <><div className={styles.syncMain}>
            <PageHeader showBackLink={false} title={'MIS Update: Existing Students - 3/3 Manual Match'} />

            <p className="u-m-base-2">Please manually match or remove these students:</p>
           
            <div className="u-flex-center u-m-base-3">
                <div className="u-m-right-3 u-flex-center">
                    <CheckCircle size={18} className="u-m-right-1" /> Matched: {getStats('matched')}
                </div>
                <div className={cx("u-m-right-3 u-flex-center", { 'u-text-danger': getStats('not_matched') >0})}>
                    <AlertCircle size={18} className="u-m-right-1" /> Not Matched: {getStats('not_matched')}
                </div>
                <div className="u-m-right-3 u-flex-center">
                    <Delete size={18} className="u-m-right-1" /> Will be removed: {getStats('removed')}
                </div>
            </div>
           
            {stMatchError && <p className="u-text-danger u-m-top-2">Please update all non matched accounts!</p>}
            {formErrors && <FormErrors errors={formErrors} />}
            <div className={styles.manualMatch}>
                <div>
                    <p>Currently in <b>BoomHub</b>:</p>
                    <div className={cx(styles.manualMatchBoom)}>
                        <div>Name</div>
                        <div className="u-text-right">Year</div>
                    </div>
                </div>
                <div>
                    <p>Action from your <b>MIS</b>:</p>
                    <div className={styles.manualMatchWonde}>
                        <div>Find or remove the student</div>
                    </div>
                </div>
            </div>

            <ul className="u-m-base-3">
                {students.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(student =>
                    <ManualStudentItem
                        key={`manual-${student.id}`} 
                        setStudents={setStudents} 
                        students={students} 
                        student={student}
                        school={school}
                     />
                )}
            </ul>
        </div>
            <div className={styles.syncFooter}>
                {/* <button onClick={() => ''} className="btn-reset u-flex-center heavy"><AlertCircle className="u-m-right-1" size={18} /> Help</button> */}
                <Button disabled={loading} className='u-m-left-auto' onClick={() => finalizeManualSync()}>{loading ? 'Saving...' : 'Confirm and continue'}</Button>
            </div>
        </>
    )
}

export default ManualMatchContent;