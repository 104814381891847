import React, { useState, useEffect, useRef } from "react";
import { getYearGroupShortName } from "../../../../services/utils";
import { Edit, CheckCircle, Trash2 } from "react-feather";
import { useLazyQuery } from "@apollo/react-hooks";
import styles from '../../WondeSync.module.scss';
import cx from 'classnames';
import loader from '../../../../images/loader.svg';
import { SEARCH_WONDE_STUDENTS } from "../../services/graphql";

const ManualStudentItem = ({ school, student, setStudents, students }) => {


    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);

    const intervalRef = useRef(null);

    const [searchWondeStudents, { loading, error }] = useLazyQuery(SEARCH_WONDE_STUDENTS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getWondeStudentsByNameOrUpn.length > 0) {
                setResults(data.getWondeStudentsByNameOrUpn);
                setNoResults(false);
            } else {
                setResults([]);
                setNoResults(true);
            }
        }
    });


    const onSelectMatch = result => {
        setResults([]);
        setNoResults(false);
        setSearchTerm('');
        const updatedStudents = students.map(el => el.id === student.id ? { ...el, wondeStudentId: result.wondeId, matchTo: result } : el)
        setStudents([...updatedStudents]);

    }

    const onEditMatch = () => {
        const updatedStudents = students.map(el => el.id === student.id ? { ...el, matchTo: null, wondeStudentId: null } : el)
        setStudents([...updatedStudents]);
    }

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            intervalRef.current = setTimeout(() => {
                searchWondeStudents({
                    variables: {
                        nameOrUpn: searchTerm,
                        wondeSchoolId: school.wondeId
                    }
                })
            }, 1000);
        } else {
            setResults([]);
            clearTimeout(intervalRef.current);
        }
        return () => clearTimeout(intervalRef.current);
    }, [searchTerm]);

    const deleteSudent = () => {
        const updatedStudents = students.map(el => el.id === student.id ? { ...el, wondeStudentId: '-1' } : el)
        setStudents(updatedStudents);
    }

    return (

        <li className={cx(styles.manualMatch, styles.altRow)}>
            <div className={cx(styles.manualMatchBoom, styles.altRowBoom)}>
                <div>{student.name}</div>
                <div className="u-text-right">{getYearGroupShortName(student.yearGroup)}</div>
            </div>
            <div className={cx(styles.manualMatchWonde, styles.altRowWonde)}>
                {student.matchTo ?
                    <>
                        <div>Matched to: {student.matchTo.wondeUpn} {student.matchTo.firstName} {student.matchTo.lastName} ( {student.matchTo.wondeYearGroup})</div>
                        <div className="u-text-right">
                            <button className="btn-reset" onClick={() => ''}><Edit size={20} /></button>
                        </div>
                    </> : student.wondeStudentId === '-1' ? (
                        <>
                            <div className="u-flex-center"><CheckCircle size={18} className="u-m-right-1"/>Will be removed (archived)</div>
                            <button className="btn-reset u-m-left-auto" onClick={() => onEditMatch()}><Edit size={18} /></button>
                        </>
                    ) :
                        <>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    className={styles.searchStudent}
                                    placeholder="Type name or UPN"
                                    autoComplete='off'
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value); setNoResults(false) }}

                                />

                                {error && <p>{error.message}</p>}
                                <div className={styles.resultsWrapper}>
                                    {loading && <p className="u-flex-center">Loading... <img src={loader} alt='' className="u-m-left-2" width={25} /></p>}
                                    {results.length > 0 ? (
                                        <ul className={styles.resultsWrapperList}>
                                            {results.map(result => (
                                                <li key={`result-${result.id}`} onClick={() => onSelectMatch(result)} className="u-flex-center u-justify-between">
                                                    <div>
                                                        <p>{result.firstName} {result.lastName}</p>
                                                        <p>{result.wondeUpn}</p>
                                                    </div>
                                                    <CheckCircle />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : noResults && <p>No students found</p>}
                                </div>
                            </div>
                            <button className="btn-reset u-m-left-auto" onClick={() => deleteSudent()}><Trash2 size={18} /></button>

                        </>
                }
            </div>
        </li>
    )
}

export default ManualStudentItem;