import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';

import { Loader } from '@axeedge/go-teacher-components';
//import { AuthActionCable } from '@axeedge/go-shared-components';
import { ActionCableWrapper, NotifCableConsumer } from './ActionCableWrapper';
import { getCableEndpoint, getBoomMathCableEndpoint } from '@axeedge/go-shared-utils';
import { Button } from '@axeedge/go-teacher-components';
import Header from '../../../components/Header';
import Nav from '../../../components/Nav';
import { CURRENT_TEACHER_QUERY } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';
import JobRoleCheck from '../../../components/JobRoleCheck';

const LoggedInRouter = ({ children, school, setSchool }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();
    const [showSocketWarning, setShowSocketWarning] = useState(false);


    const { error, loading } = useQuery(CURRENT_TEACHER_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ currentTeacher }) => {
            setCurrentUser(currentTeacher);
        },
        pollInterval: 60000
    });

    useEffect(() => {
        setSchool(currentUser.school);
    }, [currentUser, setSchool]);


    // logout users removed from school
    const onForcedLogout = () => {
        ls.clear();
        setCurrentUser(null);
        client.clearStore();
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (currentUser.id) {
        return (
            <div className='app__layout'>
                {ls('noSocket') && <button className='noSocket' onClick={() => setShowSocketWarning(true)}><span className="u-bold">Important Information</span> (click here)</button>}
                {ls('go_impersonating') && <p className='impersonating'>Impersonating Active</p>}
                {showSocketWarning &&
                    <div className="socketInfo">
                        <p className="socketPara">We've noticed that either the device or network you are using may be blocking some important domains.</p>
                        <p className="socketPara">If you experience any issues using the website, please ensure your technical support have allowed the following domain to be accessible from your school internet:</p>
                        <p className="u-bold socketPara">{ls('country') === 'US' ? getBoomMathCableEndpoint() : getCableEndpoint()}</p>
                        <p className="socketPara">This is used to allow the app to access data using WebSocket Secure protocol (wss). You may need to add a specific rule for this.</p>
                        <Button onClick={() => setShowSocketWarning(false)}>Close</Button>
                    </div>
                }
                <ActionCableWrapper>
                    <NotifCableConsumer id={currentUser.id} onLogout={() => console.log('logging out...')} onForcedLogout={onForcedLogout} />
                    <Fragment>
                        <Header hasSchool={school} />
                        <Nav user={currentUser} source="PORTAL" />
                        {currentUser.school && !currentUser.roleId && <JobRoleCheck />}
                    </Fragment>
                    <div className='app__content'>
                        {children}
                    </div>
                </ActionCableWrapper>
            </div>
        );
    }
}

export default LoggedInRouter;
