import React, { useState } from 'react';
import { GET_CLASS_STUDENTS_LIST } from '../../services/graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import axios from 'axios';
import { Modal } from '@axeedge/go-teacher-components';

import StudentsList from './components/StudentsList';
import UploadStudentsList from '../../components/UploadStudentsList';
import FinalizeUploadStudents from '../../components/UploadStudentsList/FinalizeUploadStudents';

import AddExistingStudents from '../../components/AddExistingStudents';
import { Printer, Download, PlusCircle, Upload } from 'react-feather';
import PrintableStudentsList from './components/PrintableStudentsList';
import { parse } from "query-string";
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from '../../Classes.module.scss';

import AddStudent from './components/AddStudent';

const Students = ({ students, classData, school }) => {

    const location = useLocation();

    const { t } = useTranslation(['classes']);
    const [showAddStudentModal, setShowAddStudentModal] = useState(false);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [manageUploadData, setManageUploadData] = useState(null);

    const [getClassStudentList, { loading: csvLoading }] = useLazyQuery(GET_CLASS_STUDENTS_LIST, {
        variables: {
            studentsClassId: classData.id
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            axios({
                method: 'get',
                url: data.classStudentsList,
                responseType: 'blob'
            }).then((response => {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/csv' });
                let link = document.createElement('a');
                link.href = downloadUrl;
                const fileName = `Class_login_details_${classData.name}`;
                link.setAttribute('download', fileName + '.csv');
                document.body.appendChild(link);
                link.click();
            }));
        }
    });

    if (parse(location.search).printStudents) {
        return <PrintableStudentsList school={school} students={classData.students} />
    }

    if (manageUploadData) {
        return (
            <FinalizeUploadStudents
                classData={classData}
                setManageUploadData={setManageUploadData}
                manageUploadData={manageUploadData}
                setUploaded={setUploaded}
            />
        )
    }

    return (
        <div className='row'>
            <div className='col-md u-m-base-2'>
                <div className='card-mobile'>
                    <StudentsList classId={classData.id} schoolId={school.id} students={students} />
                    {students.length === 0 && !school.wondeAccessApprovedAt && (
                        <div className={styles.shadowPanel}>
                            <p className='heavy'>Adding Students</p>
                            <hr className='separator' />
                            <button onClick={() => setShowAddStudentModal(true)} className='btn-icon u-m-base-1'><PlusCircle size='18' /> {t('create_new_pupil')}</button>
                            <AddExistingStudents schoolId={school.id} classId={classData.id} />
                            <hr className='separator' />
                            <button className='btn-icon u-m-base-1' onClick={() => setShowUploadModal(true)}>
                                <Upload size='18' className={styles.buttonIcon} />{uploaded ? 'Upload another list' : 'Import Students*'}
                            </button>
                            <div className="u-display-flex"><span className="u-bold" style={{ marginRight: '5px' }}>*</span><span>Only for creating new student accounts</span></div>
                        </div>
                    )}
                </div>
            </div>
            <div className={cx('col-md', styles.containerSide)}>
                {
                    students.length > 0 && (
                        <>
                            <div className={cx(styles.shadowPanel, 'u-m-base-2')}>
                                <p className='heavy'>{t('pupil_login_sheets')}</p>
                                <hr className='separator' />
                                <a
                                    href={`/classes/${classData.id}?printStudents=true`}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    <button type='button' className='btn-icon u-m-base-2'><Printer size="18" />{t('print_login_sheets')} </button>
                                </a>
                                <button type='button' className='btn-icon' disabled={csvLoading} onClick={() => getClassStudentList()}>
                                    <Download size='18' />{csvLoading ? t('downloading_csv_please_wait') : t('download_logins_csv')}
                                </button>
                            </div>
                            {!school.wondeAccessApprovedAt &&
                                <div className={styles.shadowPanel}>
                                    <p className='heavy'>Adding Students</p>
                                    <hr className='separator' />
                                    <button onClick={() => setShowAddStudentModal(true)} className='btn-icon u-m-base-1'><PlusCircle size='18' /> {t('create_new_pupil')}</button>
                                    <AddExistingStudents schoolId={school.id} classId={classData.id} />
                                    <hr className='separator' />
                                    <button className='btn-icon u-m-base-1' onClick={() => setShowUploadModal(true)}>
                                        <Upload size='18' className={styles.buttonIcon} />{uploaded ? 'Upload another list' : 'Import Students*'}
                                    </button>
                                    <div className="u-display-flex"><span className="u-bold" style={{ marginRight: '5px' }}>*</span><span>Only for creating new student accounts</span></div>
                                </div>
                            }
                        </>
                    )
                }
            </div>
            {
                showUploadModal && (
                    <Modal closeModal={() => setShowUploadModal(false)}>
                        <UploadStudentsList close={() => setShowUploadModal(false)} studentsClassId={classData.id} setManageUploadData={setManageUploadData} />
                    </Modal>
                )
            }

            {
                showAddStudentModal && (
                    <Modal closeModal={() => setShowAddStudentModal(false)}>
                        <AddStudent classId={classData.id} />
                    </Modal>
                )
            }
        </div>
    )
}

export default Students;
