import React from 'react';
import styles from './Help.module.scss';


const Help = () => {

    const hostname = window && window.location && window.location.hostname;
    const getHelpLink = () => {
        if (hostname.includes('staging')) {
            return hostname.includes('boom') ? 'https://staging.help.boomhub.app' :'https://staging-help.goapps.app'
        }
        return hostname.includes('boom') ? 'https://help.boomhub.app' :'https://help.goapps.app/'
    }

    return (
        <>
            <a href={getHelpLink()} target="blank" className={styles.helpLabel}>Help &amp; Guides</a>
            <a href={getHelpLink()} target="blank" className={styles.helpButton}>?</a>
        </>
    )
}

export default Help;